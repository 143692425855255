.main-panel {
  > .panel-top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $color-primary;
    width: 100%;
    height: rem(80);
    box-shadow: rem(0) rem(-2) rem(6) 0 rgba($color-black, 0.3);
    > .user-info {
      display: flex;
      align-items: center;
      .profile {
        text-align: right;
        padding: 0 rem(16);
        .name {
          font-size: $font-lg;
        }
        .email {
          font-size: $font-xxs;
        }
      }
      .image {
        width: rem(45);
        height: rem(45);
        border-radius: 100%;
        overflow: hidden;
      }
    }
    > .logout {
      width: rem(60);
      height: 100%;
      button {
        background-color: $color-primary;
        width: 100%;
        height: 100%;
        border: 0;
        svg {
          fill: $color-gunmetal;
        }
      }
    }
  }
  > .side-bar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $color-primary;
    width: rem(200);
    height: 100vh;
    box-shadow: -rem(4) rem(4) rem(10) 0 $color-third;
    .logo-box {
      height: rem(80);
      text-align: center;
      background-color: $color-gunmetal;
      > a {
        display: block;
      }
      img {
        width: rem(150);
      }
    }
  }
  .listItem {
    list-style-type: none;
    width: 100%;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      > a {
        text-decoration: none;
        display: block;
        height: 100%;
        width: 100%;
        padding: 10px 0 10px 20px;
        color: $color-gunmetal;
        &:active,
        &:hover,
        &.item-active {
          background-color: darken($color-primary, 5%);
          color: $color-gunmetal;
        }
      }
    }
  }
  > .panel-content {
    padding-left: rem(200);
  }
}
