$color-black: #1b1b1b;
$color-white: #ffffff;

$color-gunmetal: #30343c;
$color-cultured: #ebf0f1;
$color-gainsboro: #d5dbdc;

$color-acid-green: #b1c72a;
$color-blue-green: #159bc7;
$color-fandango-pink: #ea3d7f;

$color-russian-violet: #421e54;
$color-rebecca-purple: #712996;
$color-aero: #7ebfe4;
$color-cyan-process: #00bcef;
$color-imperial-red: #f33540;

$color-primary: $color-acid-green;
$color-secondary: $color-blue-green;
$color-third: $color-russian-violet;

$color-success: $color-acid-green;
$color-warning: $color-rebecca-purple;
$color-error: $color-imperial-red;

$color-link: $color-blue-green;

// Font Family
$font-primary: 'Signika';
$font-family: $font-primary, 'Helvetica', 'Arial', 'sans-serif';

// Font Weights
$light: 300;
$regular: 400;
$bold: 700;

$font-xxs: rem(12);
$font-xs: rem(14);
$font-sm: rem(16);
$font-md: rem(18);
$font-lg: rem(24);
$font-xl: rem(36);
$font-xxl: rem(40);

$line-xs: rem(16);
$line-sm: rem(20);
$line-md: rem(22);
$line-lg: rem(28);
$line-xl: rem(40);
$line-xxl: rem(46);

$margin-sm: rem(15);
$margin-md: rem(30);
$margin-lg: rem(45);
$margin-xl: rem(60);

// Breakpoints
$phone-sm: 576px;
$tablet-md: 768px;
$desktop-lg: 992px;
$desktop-xl: 1200px;
$desktop-xxl: 1600px;
