.page-section {
  @extend %flex-center;
  flex-direction: column;
  .page-header {
    text-align: center;
    padding-top: rem(40);
  }
  .page-content {
    text-align: center;
    padding-top: rem(40);
  }
  .page-footer {
    text-align: center;
    padding-top: rem(40);
  }
  &.login-page {
    .page-content {
      .btn-google-login {
        margin: rem(10) auto 0;
      }
    }
  }
}
