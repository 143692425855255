*,
*::before,
*::after {
  outline: 0;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

* {
  font-family: 'Signika';
}

html,
body {
  font-size: 16px;
  font-weight: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #f9f9f9;
}

p {
  font-size: $font-sm;
  line-height: $line-sm;
}

a {
  font-size: $font-sm;
  font-weight: $regular;
  text-decoration: underline;
  cursor: pointer;
  color: $color-acid-green;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.aws-btn {
  --slider-height-percentage: 50%;
  --slider-transition-duration: 431ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 3px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #ffffff;
  --control-button-width: 6%;
  --control-button-height: 25%;
  --control-button-background: #ffcb29;
  --control-bullet-color: #ffffff;
  --control-bullet-active-color: #ffffff;
  --loader-bar-color: #c33737;
  --loader-bar-height: 3px;
}

.MuiCircularProgress-colorPrimary {
  color: #020c90 !important;
}
